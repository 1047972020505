<template>
  <div class="Register overflow-hidden">
    <div class="MaskGroup absolute left-0 top-0">
      <img
        :src="group239"
        style="width: 732.5px; height: 703px; object-fit: cover; object-position: 0 100%"
      />
    </div>

    <div class="register-card">
      <div class="Ellipse38 w-32 h-32 absolute rounded-full right-34 top-1 z-0 bg-hwarningd"></div>
      <div class="Ellipse39 w-10 h-10 absolute rounded-full right-64 top-31 z-0 bg-hwarningd"></div>

      <div class="inline-flex flex-col space-y-0.5 items-center justify-end w-full mt-5">
        <div class="register-title">User Customer Register</div>
      </div>

      <div class="relative mt-4 mx-auto" style="max-width: 417px">
        <treeselect
          ref="reg-cus"
          class="h-10 pl-9"
          :multiple="true"
          :async="true"
          :load-options="loadOptions"
          style="box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.1)"
        />
        <div class="absolute left-0 inset-y-0 flex items-center">
          <IconCustomer class="h-5 w-5 ml-3" />
        </div>
      </div>

      <div class="relative mt-3 mx-auto" style="max-width: 417px">
        <div class="flex">
          <div class="flex-shrink-0">
            <IconAddress class="h-5 w-5 ml-3" />
          </div>
          <div class="flex-shrink px-2.5">
            <p class="font-medium text-sm text-black leading-4">
              Perum Bumi Rizka Indah Blok B/01 Jl. Kabupaten Km. 4,5 Kronggahan, Gamping, Sleman -
              Yogyakarta
            </p>
          </div>
        </div>
        <div class="flex mt-1.5">
          <div class="flex-shrink-0 sm:mr-2">
            <IconInstrument class="h-5 w-5 ml-3" />
          </div>

          <div class="flex-auto self-center p-1">
            <div class="grid grid-flow-col auto-cols-max bg-hgray1 h-5">
              <div class="pr-1">
                <div class="h-full w-0.8 bg-hblue1"></div>
              </div>

              <div class="text-xs font-bold text-black" style="line-height: 1.3rem">
                XP-1200-A8455
              </div>
            </div>
          </div>
          <div class="flex-auto self-center p-1">
            <div class="grid grid-flow-col auto-cols-max bg-hgray1 h-5">
              <div class="pr-1">
                <div class="h-full w-0.8 bg-hblue1"></div>
              </div>

              <div class="text-xs font-bold text-black" style="line-height: 1.3rem">
                959 MUX10-LM-5262
              </div>
            </div>
          </div>
          <div class="flex-auto self-center p-1">
            <div class="grid grid-flow-col auto-cols-max bg-hgray1 h-5">
              <div class="pr-1">
                <div class="h-full w-0.8 bg-hblue1"></div>
              </div>

              <div class="text-xs font-bold text-black" style="line-height: 1.3rem">
                958-test HCLAB
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="relative mt-7 mx-auto px-10">
        <div class="grid xs:grid-cols-1 sm:grid-cols-12 gap-2">
          <div class="xs:col-span-12 sm:col-span-5">
            <div
              class="text-center cursor-pointer hover:bg-habu1 mb-3"
              :class="menu == 'add' ? 'reg-menu-active' : ''"
              @click="setMenu('add')"
              style="border-radius: 5px 5px 0 0"
            >
              <div class="pt-3 pb-3">
                <img :src="ImgUserPlus" class="inline mr-4" />
                <span class="font-medium text-black">Add User</span>
              </div>
              <div
                class="w-full"
                v-if="menu == 'add'"
                style="border: 3px solid #ff0701; height: 0px"
              ></div>
            </div>

            <div class="relative mt-2">
              <input
                class="appearance-none border-none bg-habu1 pl-10 focus:placeholder-gray-800 placeholder-gray-500 transition rounded-lg w-full py-2 px-3 h-9 text-gray-800 focus:ring-transparent text-sm"
                id="username"
                type="text"
                placeholder="Name..."
                :disabled="menu != 'add'"
              />
              <div class="absolute left-0 inset-y-0 flex items-center">
                <svg
                  width="10"
                  height="12"
                  viewBox="0 0 10 12"
                  class="ml-3.5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse cx="4.79284" cy="2.21631" rx="2.10021" ry="2.21631" fill="#7D7D7D" />
                  <ellipse cx="5" cy="8.7485" rx="5" ry="3.25155" fill="#7D7D7D" />
                </svg>
              </div>
            </div>

            <div class="relative mt-2">
              <input
                class="appearance-none border-none bg-habu1 pl-10 focus:placeholder-gray-800 placeholder-gray-500 transition rounded-lg w-full py-2 px-3 h-9 text-gray-800 focus:ring-transparent text-sm"
                id="phone"
                type="text"
                placeholder="Phone..."
                :disabled="menu != 'add'"
              />
              <div class="absolute left-0 inset-y-0 flex items-center">
                <svg
                  class="ml-3.5"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.77778 0.240954C2.77778 0.240954 0.555556 -0.3012 0 0.240954C0 8.04819 6.66667 10 10 10V7.28915C10 7.28915 7.77778 6.20482 7.22222 6.20482C6.66667 7.83132 5.10126 7.02805 3.88889 5.66265C3.04782 4.71541 2.59861 3.70731 3.88889 2.9518C3.88889 2.9518 3.33333 0.783113 2.77778 0.240954Z"
                    fill="#7D7D7D"
                  />
                </svg>
              </div>
            </div>

            <div class="relative mt-2">
              <input
                class="appearance-none border-none bg-habu1 pl-10 focus:placeholder-gray-800 placeholder-gray-500 transition rounded-lg w-full py-2 px-3 h-9 text-gray-800 focus:ring-transparent text-sm"
                id="position"
                type="text"
                placeholder="Position..."
                :disabled="menu != 'add'"
              />
              <div class="absolute left-0 inset-y-0 flex items-center">
                <svg
                  class="ml-3.5"
                  width="10"
                  height="12"
                  viewBox="0 0 10 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse cx="4.85729" cy="2.21631" rx="2.10021" ry="2.21631" fill="#7D7D7D" />
                  <ellipse cx="5" cy="8.74838" rx="5" ry="3.25155" fill="#7D7D7D" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.20972 6.50004C5.14039 6.49791 5.07048 6.49683 5.00003 6.49683C4.86477 6.49683 4.73151 6.50082 4.60048 6.50855C4.43152 6.27458 4.27581 5.89128 4.15625 5.54293C4.43055 5.51261 4.71245 5.49683 5.00003 5.49683C5.22175 5.49683 5.4401 5.50621 5.65419 5.5244C5.53525 5.87344 5.37932 6.26114 5.20972 6.50004Z"
                    fill="white"
                  />
                  <path
                    d="M3.92163 10.3325C3.92163 9.88202 4.41183 6.729 4.90202 6.729C5.39222 6.729 5.88241 9.88202 5.88242 10.3325C5.88241 10.7829 3.92163 10.7829 3.92163 10.3325Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            <div class="relative mt-2">
              <input
                class="appearance-none border-none bg-habu1 pl-10 focus:placeholder-gray-800 placeholder-gray-500 transition rounded-lg w-full py-2 px-3 h-9 text-gray-800 focus:ring-transparent text-sm"
                id="email"
                type="text"
                placeholder="Email..."
                :disabled="menu != 'add'"
              />
              <div class="absolute left-0 inset-y-0 flex items-center">
                <svg
                  class="ml-3.5"
                  width="11"
                  height="9"
                  viewBox="0 0 11 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="11" height="9" rx="1.3" fill="#7D7D7D" />
                  <mask
                    id="mask0_482_202"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="11"
                    height="9"
                  >
                    <rect width="11" height="9" rx="1.3" fill="#7D7D7D" />
                  </mask>
                  <g mask="url(#mask0_482_202)">
                    <path
                      d="M11 3.25L8 7.525L0 3.25"
                      stroke="white"
                      stroke-width="0.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div class="relative mt-2">
              <input
                class="appearance-none border-none bg-habu1 pl-10 focus:placeholder-gray-800 placeholder-gray-500 transition rounded-lg w-full py-2 px-3 h-9 text-gray-800 focus:ring-transparent text-sm"
                id="cemail"
                type="text"
                placeholder="Email confirm..."
                :disabled="menu != 'add'"
              />
              <div class="absolute left-0 inset-y-0 flex items-center">
                <svg
                  class="ml-3.5"
                  width="11"
                  height="9"
                  viewBox="0 0 11 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.1 2H9.9C10.505 2 11 2.39375 11 2.875V8.125C11 8.60625 10.505 9 9.9 9H1.1C0.495 9 0 8.60625 0 8.125V2.875C0 2.39375 0.495 2 1.1 2Z"
                    fill="#7D7D7D"
                  />
                  <path d="M1 0V3.81818L5.5 6L10 3.81818V0H1Z" fill="#CBCBCB" />
                  <path
                    d="M4.22632 2.79057L5.29062 3.90682L7.41923 1.67432"
                    stroke="#7D7D7D"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div class="mt-3">
              <button
                class="btn h-9 rounded-lg border-0 w-full text-white bg-hwarningd"
                :disabled="menu != 'add'"
              >
                <span class="text-sm capitalize">Register</span>
              </button>
            </div>
          </div>

          <div class="col-span-2 self-center xs:invisible sm:visible pt-16">
            <div class="mx-auto" style="border: 1px solid #c8c8c8; height: 235px; width: 0px"></div>
          </div>

          <div class="xs:col-span-12 sm:col-span-5">
            <div
              class="text-center cursor-pointer hover:bg-habu1 mb-3"
              :class="menu == 'reset' ? 'reg-menu-active' : ''"
              @click="setMenu('reset')"
              style="border-radius: 5px 5px 0 0"
            >
              <div class="pt-3 pb-3">
                <img :src="ImgResetPassword" class="inline mr-4" />
                <span class="font-medium text-center text-black">Reset Password</span>
              </div>

              <div
                class="w-full"
                v-if="menu == 'reset'"
                style="border: 3px solid #ff0701; height: 0px"
              ></div>
            </div>

            <div class="relative">
              <treeselect
                ref="reg-cus"
                class="h-9 pl-9"
                :multiple="true"
                :async="true"
                :load-options="loadOptions"
                style="box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.1); border-radius: 8px"
                :disabled="menu != 'reset'"
                :style="menu != 'reset' ? 'background-color:#f9f9f9' : ''"
              />
              <div class="absolute left-0 inset-y-0 flex items-center bg-transparent">
                <svg
                  width="10"
                  height="12"
                  viewBox="0 0 10 12"
                  class="ml-3.5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse cx="4.79284" cy="2.21631" rx="2.10021" ry="2.21631" fill="#7D7D7D" />
                  <ellipse cx="5" cy="8.7485" rx="5" ry="3.25155" fill="#7D7D7D" />
                </svg>
              </div>
            </div>

            <div class="flex mt-8">
              <div class="flex-shrink-0 pt-1">
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.77778 0.240954C2.77778 0.240954 0.555556 -0.3012 0 0.240954C0 8.04819 6.66667 10 10 10V7.28915C10 7.28915 7.77778 6.20482 7.22222 6.20482C6.66667 7.83132 5.10126 7.02805 3.88889 5.66265C3.04782 4.71541 2.59861 3.70731 3.88889 2.9518C3.88889 2.9518 3.33333 0.783113 2.77778 0.240954Z"
                    fill="#7D7D7D"
                  />
                </svg>
              </div>
              <div class="flex-shrink sm:px-2">
                <p class="font-medium text-sm text-black leading-4">08132xxxxxx</p>
              </div>
            </div>
            <div class="flex mt-5">
              <div class="flex-shrink-0 pt-1">
                <svg
                  width="16"
                  height="13"
                  viewBox="0 0 16 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="16" height="13" rx="1.5" fill="#7D7D7D" />
                  <mask
                    id="mask0_482_202"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="16"
                    height="13"
                  >
                    <rect width="16" height="13" rx="1.5" fill="#7D7D7D" />
                  </mask>
                  <g mask="url(#mask0_482_202)">
                    <path
                      d="M16 3.25L8 8.125L0 3.25"
                      stroke="white"
                      stroke-width="0.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
              </div>
              <div class="flex-shrink sm:px-2">
                <p class="font-medium text-sm text-black leading-4">account.test@gmail.com</p>
              </div>
            </div>

            <div class="flex mt-5">
              <div class="flex-shrink-0 pt-1">
                <svg
                  width="10"
                  height="12"
                  viewBox="0 0 10 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse cx="4.85729" cy="2.21631" rx="2.10021" ry="2.21631" fill="#7D7D7D" />
                  <ellipse cx="5" cy="8.74838" rx="5" ry="3.25155" fill="#7D7D7D" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.20972 6.50004C5.14039 6.49791 5.07048 6.49683 5.00003 6.49683C4.86477 6.49683 4.73151 6.50082 4.60048 6.50855C4.43152 6.27458 4.27581 5.89128 4.15625 5.54293C4.43055 5.51261 4.71245 5.49683 5.00003 5.49683C5.22175 5.49683 5.4401 5.50621 5.65419 5.5244C5.53525 5.87344 5.37932 6.26114 5.20972 6.50004Z"
                    fill="white"
                  />
                  <path
                    d="M3.92163 10.3325C3.92163 9.88202 4.41183 6.729 4.90202 6.729C5.39222 6.729 5.88241 9.88202 5.88242 10.3325C5.88241 10.7829 3.92163 10.7829 3.92163 10.3325Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div class="flex-shrink sm:px-2">
                <p class="font-medium text-sm text-black leading-4">Kepala Lab</p>
              </div>
            </div>

            <div class="mt-8">
              <button
                class="btn h-9 rounded-lg border-0 w-full text-white bg-hwarningd"
                :disabled="menu != 'reset'"
              >
                <span class="text-sm capitalize">Reset</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="Ellipse27 absolute right-0 bottom-0">
      <img :src="ellipse27" />
    </div>
  </div>
</template>

<script>
import bgDecor from '@/assets/images/bg_decor.png'
import group239 from '@/assets/images/group239.png'
import gpIcon from '@/assets/images/google_play.png'
import icareLogo from '@/assets/images/i_care_logo_large.png'
import ellipse27 from '@/assets/images/ellipse27.png'
import ImgUserPlus from '@/assets/images/userplus.png'
import ImgResetPassword from '@/assets/images/resetpassword.png'

import IconCustomer from '@/assets/icons/icon-customer.svg?inline'
import IconAddress from '@/assets/icons/icon-address.svg?inline'
import IconInstrument from '@/assets/icons/icon-instrument.svg?inline'
// import IconProfile from '@/assets/icons/icon-profile.svg?inline'

import Treeselect from '@riophae/vue-treeselect'
import { ASYNC_SEARCH } from '@riophae/vue-treeselect'

const simulateAsyncOperation = fn => {
  setTimeout(fn, 2000)
}

export default {
  name: 'register-page',
  components: { Treeselect, IconCustomer, IconAddress, IconInstrument },
  data() {
    return {
      bgDecor,
      group239,
      gpIcon,
      icareLogo,
      ellipse27,
      ImgUserPlus,
      ImgResetPassword,
      menu: 'add'
    }
  },
  methods: {
    loadOptions({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH) {
        simulateAsyncOperation(() => {
          const options = [1, 2, 3, 4, 5].map(i => ({
            id: `${searchQuery}-${i}`,
            label: `${searchQuery}-${i}`
          }))
          callback(null, options)
        })
      }
    },
    setMenu(val) {
      this.menu = val
    }
  }
}
</script>
